import { useEffect, useState } from "react";
import { AuthContext, SecondaryAuthContext } from "./Context";
import type { User } from "firebase/auth";
import { auth } from "../../firebaseSetup";
import { secondaryAuth } from "../../secondaryFirebaseSetup";

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      console.log("firebaseUser", firebaseUser);
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const SecondaryAuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = secondaryAuth.onAuthStateChanged((firebaseUser) => {
      console.log("firebaseUser", firebaseUser);
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);

  return (
    <SecondaryAuthContext.Provider value={user}>
      {children}
    </SecondaryAuthContext.Provider>
  );
};
