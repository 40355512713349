import * as React from "react";
import { NavigationOptions, NavigationState } from "./types";
import { NavigationContext, useNavigationContext } from "./Context";

export function useNavigationState(): NavigationState {
    const [navigation, setNavigation] = React.useState<NavigationOptions>("User");

    return {
        navigation,
        setNavigation: (navigation: NavigationOptions) => {
            console.log("Setting navigation to: ", navigation);
            setNavigation(navigation)
        },
    };
}

export function useNavigation(): NavigationOptions {
   const { navigation } = useNavigationContext();
    return navigation;
}

export function useSetNavigation(): (navigation: NavigationOptions) => void {
    const { setNavigation } = useNavigationContext();
    return setNavigation;
}

export function NavigationStateProvider({ children }: { children: React.ReactNode }) {
    const navigationState = useNavigationState();

    return <NavigationContext.Provider value={navigationState}>{children}</NavigationContext.Provider>;
}