import * as React from "react";
import type { User } from "firebase/auth";

export const AuthContext = React.createContext<User | null>(null);

export const useAuth = () => React.useContext(AuthContext);

export const useIsSignedIn = () => {
  const auth = useAuth();
  return !!auth;
};

export const SecondaryAuthContext = React.createContext<User | null>(null);

export const useSecondaryAuth = () => React.useContext(SecondaryAuthContext);

export const useIsSignedInSecondary = () => {
  const auth = useSecondaryAuth();
  return !!auth;
};
