import { ITheme, memoizeFunction, mergeStyleSets } from "@fluentui/react";

export type AppStyles = {
    navigation: string;
}

export const getAppStyles = memoizeFunction((theme: ITheme): AppStyles => {
    return mergeStyleSets({
        navigation: {
            backgroundColor: theme.palette.themeLighter
        }
    });
});