import {
  INavStyleProps,
  INavStyles,
  memoizeFunction,
  mergeStyleSets,
} from "@fluentui/react";

export type INavigationStyles = {
  root: string;
};

export const getNavigationStyles = memoizeFunction(
  (props: INavStyleProps): Partial<INavStyles> => {
    const { isDisabled } = props;
    console.log("isDisabled", isDisabled);
    return mergeStyleSets<Partial<INavStyles>>({
      root: {
        height: "100vh",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflow: "auto",
        paddingTop: "10vh",
        backgroundColor: "#fff",
      },
      link: {
        cursor: isDisabled ? "not-allowed" : "pointer",
      }
    });
  }
);
