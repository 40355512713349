import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

const secondaryFirebaseConfig = {
  apiKey: "AIzaSyDhSsPnv_3pHUXgjM5NX5LoisjYPwwWhaE",
  authDomain: "login.leelalife.com",
  projectId: "big-quanta-375810",
  storageBucket: "big-quanta-375810.appspot.com",
  messagingSenderId: "408414708622",
  appId: "1:408414708622:web:209c92cfa490e52b47fadf",
};

export const secondaryApp = initializeApp(secondaryFirebaseConfig, "secondary");

const secondaryProvider = new GoogleAuthProvider();
secondaryProvider.setCustomParameters({ prompt: "select_account" });

export const secondaryAuth = getAuth(secondaryApp);
export const signInSecondaryAccountWithGooglePopup = () => signInWithPopup(secondaryAuth, secondaryProvider);
export const signOutSecondaryUser = () => signOut(secondaryAuth);
