import * as Rect from "react";
import { NavigationState } from "./types";

export const NavigationContext = Rect.createContext<
  NavigationState | undefined
>(undefined);

export const useNavigationContext = () => {
  const context = Rect.useContext(NavigationContext);
  if (!context) {
    throw new Error(
      "useNavigationContext must be used within a NavigationProvider"
    );
  }
  return context;
};
