import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCYjBGZIiaog6WkAnsqTD_w8eDK1XKR-IM",
  authDomain: "subtle-cubist-419805.firebaseapp.com",
  projectId: "subtle-cubist-419805",
  storageBucket: "subtle-cubist-419805.appspot.com",
  messagingSenderId: "499258365998",
  appId: "1:499258365998:web:0bbd9587b7cc9bc21de7bf",
};

initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/drive.file");
provider.setCustomParameters({ prompt: "select_account" });

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signOutUser = () => signOut(auth);
