import * as React from "react";
import { Nav } from "@fluentui/react";
import { useNavigationLinkGroup } from "./useNavigationProps";
import { getNavigationStyles } from "./Navigation.styles.web";
import { NavigationLogo } from "./NavigationLogo";

export const Navigation: React.FC = () => {
  const navLinkGroups = useNavigationLinkGroup();
  //const styles = getNavigationStyles();

  return (
    <>
      <NavigationLogo />
      <Nav groups={navLinkGroups} styles={getNavigationStyles} />
    </>
  );
};
