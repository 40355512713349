import * as React from 'react';
import { Navigation } from './Components/Navigation/Navigation';
import { Dashboard } from './Components/Dashboard/Dashboard';
import { Stack, useTheme } from '@fluentui/react';
import { getAppStyles } from './App.styles';

export const App: React.FC = () => {

  const theme = useTheme();
  const styles = getAppStyles(theme);

  return (
    <>
     <div>
      <Stack enableScopedSelectors horizontal>
        <Stack.Item grow className={styles.navigation}>
          <Navigation />
        </Stack.Item>
        <Stack.Item grow={10}>
          <Dashboard />
        </Stack.Item>
      </Stack>
     </div>
     </>
  );
};

export default App;
