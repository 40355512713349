import { INavLink, INavLinkGroup } from "@fluentui/react";
import { useSetNavigation } from "../../Context/Navigation/Provider";
import { useIsSignedIn } from "../../Context/Auth/Context";

export function useNavigationLinkGroup(): INavLinkGroup[] {
  const setNavigation = useSetNavigation();
  const isSignedIn = useIsSignedIn();

  return [
    {
      links: [
        {
          name: "User",
          key: "User",
          url: "#",
          onClick: () => setNavigation("User"),
        },
        ...(isSignedIn
          ? [
              {
                name: "Recommended Products",
                key: "Recommended Products",
                url: "#",
                onClick: () => setNavigation("Recommded Products"),
                disabled: true,
              } as INavLink,
              {
                name: "Experiences",
                key: "Experiences",
                url: "#",
                onClick: () => setNavigation("Experiences"),
              } as INavLink,
              {
                name: "Products",
                key: "Products",
                url: "#",
                onClick: () => setNavigation("Products"),
                disabled: true,
              } as INavLink,
              {
                name: "Secrets",
                key: "Secrets",
                url: "#",
                onClick: () => setNavigation("Secrets"),
                disabled: true,
              } as INavLink,
              {
                name: "Specialists/Insights",
                key: "Specialists",
                url: "#",
                onClick: () => setNavigation("Specialists"),
              } as INavLink,
              {
                name: "Blueprint Flow Controller",
                key: "Blueprint Flow Controller",
                url: "#",
                onClick: () => setNavigation("Blueprint Flow Controller"),
                disabled: false,
              } as INavLink,
              {
                name: "Mail Template Generator",
                key: "Mail Template Generator",
                url: "#",
                onClick: () => setNavigation("MailTemplateGenerator"),
                disabled: false,
              } as INavLink,
              {
                name: "Leads",
                key: "Leads",
                url: "#",
                onClick: () => setNavigation("Leads"),
                disabled: false,
              } as INavLink,
              {
                name: "Plan to Holistic Wellbeing",
                key: "Plan to Holistic Wellbeing",
                url: "#",
                onClick: () => setNavigation("Plan to Holistic Wellbeing"),
                disabled: false,
              } as INavLink,
              {
                name: "Blueprint Lite",
                key: "BlueprintLite",
                url: "#",
                onClick: () => setNavigation("BlueprintLite"),
                disabled: false,
              } as INavLink,
            ]
          : []),
      ],
    },
  ];
}
