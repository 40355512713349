import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./Context/Auth/Provider";
import { NavigationStateProvider } from "./Context/Navigation/Provider";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { NotificationProvider } from "./Context/Notification/Provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
initializeIcons();
root.render(
  <React.StrictMode>
    <FluentProvider theme={webLightTheme}>
      <NotificationProvider>
        <AuthProvider>
          <NavigationStateProvider>
            <App />
          </NavigationStateProvider>
        </AuthProvider>
      </NotificationProvider>
    </FluentProvider>
  </React.StrictMode>
);
