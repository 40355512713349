import * as React from "react";
import { dispatchNotification } from "./types";

export const NotificationContext = React.createContext<
  dispatchNotification | undefined
>(undefined);

export const useNotificationContext = () => {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }
  return context;
};

export const useDispatchNotification = () => {
  const dispatchNotification = useNotificationContext();
  return dispatchNotification;
};
