import {
  Link,
  Toast,
  Toaster,
  ToastIntent,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { NotificationContext } from "./Context";
import * as React from "react";

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const dispatchNotification = React.useCallback(
    (message: string, type: ToastIntent) => {
      dispatchToast(
        <Toast>
          <ToastTitle
            action={
              <ToastTrigger>
                <Link>{"x"}</Link>
              </ToastTrigger>
            }
          >
            {message}
          </ToastTitle>
        </Toast>,
        {
          position: "top",
          intent: type,
          timeout: -1,
        }
      );
    },
    [dispatchToast]
  );

  return (
    <NotificationContext.Provider value={dispatchNotification}>
      {children}
      <Toaster toasterId={toasterId} />
    </NotificationContext.Provider>
  );
}
